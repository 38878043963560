<app-header></app-header>
<div class="background-image">
  <div class="container text-center myClass">
    <h1>ICMAD Reseller</h1>
    <p>
      Cloud-based accounting software to help you make
      smarter decisions faster with convenient retrieval of your
      critical business data from Anywhere at Anytime.
    </p>
    <br><br>
    <p class="buttons-bar">
      <a routerLink="/icmad-free-trial-sign-up" class="button_default_lemon">Free Trial</a>
      <a routerLink="/icmad-subscribe-now" class="button_default_white">Subscribe Now</a>
    </p>
    <p></p>
  </div>
</div>
<app-footer></app-footer>
