import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {
  logo: string = environment.ACS_LOGO_IMAGE;
  companyName: string = environment.companyName;
  accountantDashboardURL: string = environment.LOGIN_URL;
  constructor() { }

}
