import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { PresalesComponent } from '@app/components/presales/presales.component';
import { AppConnectComponent } from '@app/components/app-connect/app-connect.component';
import { SubscribeNowComponent } from '@app/components/subscribe-now/subscribe-now.component';
import { FreeTrialComponent } from '@app/components/free-trial/free-trial.component';
import { HeaderComponent } from '@app/components/header/header.component';
import { FooterComponent } from '@app/components/footer/footer.component';

import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ResellerComponent } from './components/reseller/reseller.component';
import { IcmadFreeTrialComponent } from './components/icmad-free-trial/icmad-free-trial.component';
import { IcmadSubscribeNowComponent } from './components/icmad-subscribe-now/icmad-subscribe-now.component';
import { EmailTestComponent } from './components/email-test/email-test.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#fecb00',
  pbColor: '#fecb00'
};

@NgModule({
  declarations: [
    AppComponent,
    PresalesComponent,
    AppConnectComponent,
    SubscribeNowComponent,
    FreeTrialComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    ResellerComponent,
    IcmadFreeTrialComponent,
    IcmadSubscribeNowComponent,
    EmailTestComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot(),
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
