import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subscribe-now',
  templateUrl: './subscribe-now.component.html',
  styleUrls: ['./subscribe-now.component.css']
})
export class SubscribeNowComponent implements OnInit, OnDestroy, AfterViewInit {

  loginURL: string = environment.LOGIN_URL;
  companyName: string = environment.companyName;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.titleService.setTitle(data.title);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const script = this.document.createElement('script');
      script.nonce = '4AEemGb0xJptoIGFP3NR';
      script.type = 'text/javascript';
      script.innerHTML = `var options = {
        widget_id: 'ACS-WIDGET',
        buyNow: true
      };
      ACSWidget.init('demo_widget', options);`;
      this.document.getElementById('head').appendChild(script);
    }, 2000);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

}
