/* eslint-disable camelcase */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ApplicationService } from '@app/services/application.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-app-connect',
  templateUrl: './app-connect.component.html',
  styleUrls: ['./app-connect.component.css']
})
export class AppConnectComponent implements OnInit, OnDestroy {
  currentDate = new Date();
  logo: string = environment.ACS_LOGO_IMAGE;
  companyName: string = environment.companyName;
  error: string;

  appName: string = environment.APPLICATION_DETAILS.NAME;
  clientId: string = environment.APPLICATION_DETAILS.CLIENT_ID;
  clientSecret: string = environment.APPLICATION_DETAILS.CLIENT_SECRET_KEY;
  state: string = environment.APPLICATION_DETAILS.STATE;
  source: string = environment.APPLICATION_DETAILS.SOURCE;
  appPrivacyLink: string;
  appTermLink: string;

  connectStatus = false;
  enableGetToken = false;
  enableFirstCall = false;

  apiConnectProjectUrl: string = environment.OAUTH_URL;
  odataBaseUrl: string = environment.O_DATA_URL;

  responseConnect: any;
  accountNumber: string;
  authenticationCode: string;

  accessTokenResponse: any;
  accessToken: string;

  firstApiCallResponse: any;

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private appService: ApplicationService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.activatedRoute.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.titleService.setTitle(data.title);
    });

    this.error = this.activatedRoute.snapshot.queryParams.error;
    if (this.error) {
      const cyrillicPattern = /\p{Script=Cyrl}/u;
      if (cyrillicPattern.test(this.error)) {
        this.toastr.error(
        'The application has encountered an unknown error. ' +
        'Our technical staff have been automatically notified and will be looking into this asap.',
        'Connect');
      } else {
        this.toastr.error(this.error, 'Connect');
      }
    }
    this.responseConnect = this.activatedRoute.snapshot.queryParams.response;
    this.responseConnect = this.responseConnect ? JSON.parse(this.responseConnect) : '';
    this.authenticationCode = this.activatedRoute.snapshot.queryParams.authenticationCode;
    this.accountNumber = this.activatedRoute.snapshot.queryParams.accountNumber;

    if (this.accountNumber && this.authenticationCode) {
      this.enableGetToken = true;
      this.connectStatus = true;
    }
    this.ngxService.stop();
  }

  connect(): void {
    this.ngxService.start();
    const url = new URL(this.apiConnectProjectUrl + '/authorize');
    url.searchParams.set('client_id', this.clientId);
    url.searchParams.set('state', this.state);
    url.searchParams.set('response_type', 'code');
    window.open(url.href, '_self');
    this.ngxService.stop();
  }

  disconnect(): void {
    this.ngxService.start();
    this.appService.disconnectApplication({
      access_token: this.accessToken,
      authentication_code: this.authenticationCode,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      app_source: this.source
    }).then(response => {
      console.log('DEBUG: AppConnectComponent -> disconnect -> response', response);
      this.ngxService.start();
    }).catch(error => {
      console.log('DEBUG: AppConnectComponent -> disconnect -> error', error);
      this.ngxService.start();
    });
  }

  getAccessToken(): void {
    this.ngxService.start();
    this.appService.getAccessToken({
      client_secret: this.clientSecret,
      client_id: this.clientId,
      code: this.authenticationCode,
      grant_type: 'authorization_code'
    }).then((response: any) => {
      console.log('DEBUG: AppConnectComponent -> getAccessToken -> response', response);
      this.accessTokenResponse = response;
      this.accessToken = response?.access_token;
      this.enableFirstCall = true;
      this.ngxService.stop();
    }).catch(error => {
      console.log('DEBUG: AppConnectComponent -> getAccessToken -> error', error);
      this.ngxService.stop();
    });
  }

  firstCall(): void {
    this.ngxService.start();
    this.appService.makeAFirstCall(
      this.odataBaseUrl,
      this.accessToken,
      this.accountNumber,
    ).then((response: any) => {
      console.log('DEBUG: AppConnectComponent -> firstCall -> response', response);
      this.firstApiCallResponse = response;
      this.ngxService.stop();
    }).catch(error => {
      console.log('DEBUG: AppConnectComponent -> firstCall -> error', error);
      this.ngxService.stop();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

}
