import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  logo: string = environment.ACS_LOGO_IMAGE;
  companyName: string = environment.companyName;
  accountantDashboardURL: string = environment.LOGIN_URL;
  constructor() { }

}
