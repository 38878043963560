<app-header></app-header>
<div class="background-image">
  <div class="container text-center myClass">
    <h1>Online Accounting &amp; Inventory</h1>
    <p>
      Cloud-based accounting software to help you make
      smarter decisions faster with convenient retrieval of your
      critical business data from Anywhere at Anytime.
    </p>
    <br><br>
    <p class="buttons-bar">
      <a routerLink="/free-trial-sign-up" class="button_default_lemon">Free Trial</a>
      <a routerLink="/subscribe-now" class="button_default_white">Subscribe Now</a>
    </p>
    <p></p>
  </div>
<!-- <iframe src='https://testnets.opensea.io/collection/myacsnft-e0lmlvk1wd?embed=true'
        width='100%'
        height='100%'
        frameborder='0'
        allowfullscreen></iframe>
</div> -->
<app-footer></app-footer>
