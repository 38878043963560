import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-presales',
  templateUrl: './presales.component.html',
  styleUrls: ['./presales.component.css']
})
export class PresalesComponent implements OnInit, OnDestroy {

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.titleService.setTitle(data.title);
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

}
