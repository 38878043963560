import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'client';

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngOnInit(): void {
    const script = this.document.createElement('script');
    script.nonce = '4AEemGb0xJptoIGFP3Nd';
    script.src = environment.LOGIN_URL + '/acs_widget/js/widget.js';
    script.async = true;
    script.defer = true;
    this.document.getElementById('head').appendChild(script);
  }
}
