import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConnectComponent } from './components/app-connect/app-connect.component';
import { PresalesComponent } from './components/presales/presales.component';
import { FreeTrialComponent } from './components/free-trial/free-trial.component';
import { SubscribeNowComponent } from './components/subscribe-now/subscribe-now.component';
import { environment } from '@env/environment';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ResellerComponent } from './components/reseller/reseller.component';
import { IcmadFreeTrialComponent } from './components/icmad-free-trial/icmad-free-trial.component';
import { IcmadSubscribeNowComponent } from './components/icmad-subscribe-now/icmad-subscribe-now.component';
import { EmailTestComponent } from './components/email-test/email-test.component';


const routes: Routes = [{
  path: '',
  component: AppConnectComponent,
  data: {
    title: `App Demo | ${environment.companyName}`
  }
}, {
  path: 'connect',
  component: AppConnectComponent,
  data: {
    title: `App Demo | ${environment.companyName}`
  }
}, {
  path: 'presales',
  component: PresalesComponent,
  data: {
    title: `Presales Demo | ${environment.companyName}`
  }
}, {
  path: 'free-trial-sign-up',
  component: FreeTrialComponent,
  data: {
    title: `Free Trial | ${environment.companyName}`
  }
}, {
  path: 'subscribe-now',
  component: SubscribeNowComponent,
  data: {
    title: `Subscribe Now | ${environment.companyName}`
  }
}, {
  path: 'reseller',
  component: ResellerComponent,
  data: {
    title: 'Presales Demo with ICMAD'
  }
}, {
  path: 'icmad-free-trial-sign-up',
  component: IcmadFreeTrialComponent,
  data: {
    title: 'ICMAD | Free Trial'
  }
}, {
  path: 'icmad-subscribe-now',
  component: IcmadSubscribeNowComponent,
  data: {
    title: 'ICMAD | Subscribe Now'
  }
}, {
  path: 'OWD-780',
  component: EmailTestComponent,
  data: {
    title: 'OWD-780 | Email Test'
  }
}, {
  path: '**',
  component: NotFoundComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
