<app-header></app-header>
<main>
    <div class="container">
        <h3 class="text-center">Email Testing with AccountingSuite™</h3>
        <br>
        <div class="card p-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email">Email address</label>
                                <input type="email" [(ngModel)]="email" class="form-control" id="email" placeholder="Enter email">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="franchiseList">Select franchise</label>
                                <select class="form-control" [(ngModel)]="franchiseeCode" id="franchiseList">
                                    <option *ngFor="let franchiseData of franchiseList" value="{{ franchiseData.value }}">{{ franchiseData.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <button type="button" (click)="onSendEmail()" class="btn btn-primary">Submit</button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="app-data app-request">
                        <h4>Request</h4>
                        <hr>
                        <div class="app-code">
                            <pre>{{ requestData | json }}</pre>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="app-data app-response">
                        <h4>Response</h4>
                        <hr>
                        <div>
                            <pre>{{ responseData | json }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<app-footer></app-footer>
