<div class="container">
  <div class="py-5 text-center">
    <img class="d-block mx-auto mb-4" src="assets/images/{{logo}}" alt="" height="72">
    <h2>{{ companyName }} App Demo</h2>
    <p class="lead">Connect your {{ companyName }} Account to {{ appName }}</p>
  </div>

  <div>
    <h4>App Credentials</h4>
    <div style="background-color:white;padding: 5px;margin-bottom: 10px;">
      <strong>AppName:</strong> {{ appName }} <br>
      <strong>ClientID:</strong> {{clientId}} <br>
      <strong>ClientSecret:</strong> {{clientSecret}} <br>
    </div>
  </div>

  <div class="section">
    <h4>Step1: Connect to {{ companyName }}</h4>
    <div class="row">
      <div class="col-md-4 mb-4">
        <a (click)="disconnect();" *ngIf="connectStatus"><img class="img-fluid" src="assets/images/Disconnect-Yellow-MED.png"></a>
        <a (click)="connect();" *ngIf="!connectStatus"><img class="img-fluid" src="assets/images/Connect-Yellow-MED.png"></a>
      </div>
      <div class="col-md-8">
        <div style="background-color:white;padding: 5px;margin-bottom: 10px;">
          <h4 class="text-center">Request</h4>
          <pre
            class="text-wrap"><code>{{apiConnectProjectUrl}}/authorize?client_id={{clientId}}&state={{state}}&response_type=code</code></pre>
        </div>
        <div style="background-color:white;padding: 5px;">
          <h4 class="text-center">Response</h4>
          <pre class="text-wrap"><code>{{responseConnect | json}}</code></pre>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <h4>Step2: Get Access token</h4><br>
    <div class="row">
      <div class="col-md-4">
        Account Number: {{accountNumber}} <br>
        Authentication Code: {{authenticationCode}} <br>
        <a *ngIf="(connectStatus && enableGetToken)" class="btn btn-warning" (click)="getAccessToken();">Get Access Token</a>
        <a *ngIf="(!connectStatus || !enableGetToken)" class="btn btn-warning" disabled>Get Access Token</a>
      </div>
      <div class="col-md-8">
        <div style="background-color:white;padding: 5px;margin-bottom: 10px;">
          <h4 class="text-center">Request</h4>
          <pre class="text-wrap">
              <code>
                  curl -X POST <br> 
                  {{apiConnectProjectUrl}}/oauth_token \-d <br>
                  client_secret={{clientSecret}} \-d <br>
                  client_id={{clientId}} \-d <br>
                  code={{authenticationCode}} \-d<br>
                  grant_type=authorization_code
              </code>
            </pre>
        </div>
        <div style="background-color:white;padding: 5px;">
          <h4 class="text-center">Response</h4>
          <pre class="text-wrap"><code>{{accessTokenResponse | json}}</code></pre>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <h4>Step3: Make an API call</h4>
    <div class="row">
      <div class="col-md-4">
        Account Number: {{accountNumber}} <br>
        AccessToken: {{accessToken}} <br>
        <a *ngIf="(connectStatus && enableGetToken && enableFirstCall)" class="btn btn-warning" (click)="firstCall();">Make a call</a>
        <a *ngIf="!(connectStatus && enableGetToken && enableFirstCall)" class="btn btn-warning" disabled>Make a call</a>
      </div>
      <div class="col-md-8">
        <div style="background-color:white;padding: 5px;margin-bottom: 10px;">
          <h4 class="text-center">Request</h4>
          <pre class="text-wrap">
                <code>
                    curl -X GET <br> 
                    {{odataBaseUrl}}/{{accountNumber}}/odata/standard.odata/Catalog_USTaxCategories1099?$format=json -H <br>
                    "Authorization: Basic {{accessToken}}" <br>
                </code>
            </pre>
        </div>
        <div style="background-color:white;padding: 5px;">
          <h4 class="text-center">Response</h4>
          <pre class="text-wrap"><code>{{firstApiCallResponse | json}}</code></pre>
        </div>
      </div>
    </div>

  </div>

  <footer class="my-5 pt-5 text-muted text-center text-small">
    <p class="mb-1">© {{currentDate.getFullYear()}} {{appName}}</p>
    <ul class="list-inline">
      <li class="list-inline-item"><a href="{{appPrivacyLink}}">Privacy</a></li>
      <li class="list-inline-item"><a href="{{appTermLink}}">Terms</a></li>
    </ul>
  </footer>
</div>
