import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  api: string = environment.OAUTH_URL;
  appDemoApi: string = environment.APP_DEMO_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  disconnectApplication(requestBody: any): Promise<any> {
    return this.httpClient.post(this.api + '/disconnect-application', requestBody).toPromise();
  }

  getAccessToken(requestBody: any): Promise<any> {
    return this.httpClient.post(this.api + '/oauth_token', requestBody).toPromise();
  }

  makeAFirstCall(odataBaseUrl: string, accessToken: string, accountNumber: string): Promise<any> {
    return this.httpClient.get(odataBaseUrl + '/' + accountNumber + '/odata/standard.odata/Catalog_USTaxCategories1099?$format=json',
    { headers: new HttpHeaders({Authorization: 'Basic ' + accessToken})}).toPromise();
  }

  sendTestEmail(requestData: any): Promise<any> {
    return this.httpClient.post(
      requestData.url,
      requestData.body,
      { headers: new HttpHeaders({Authorization: requestData.authorization})}
      ).toPromise();
  }

  getFranchiseList(): Promise<any> {
    return this.httpClient.get(this.appDemoApi + '/franchise-list').toPromise();
  }
}
