export const environment = {
  // --------Fixed-----------------------//
  production: true,
  companyName: 'AccountingSuite™',
  companyURL: 'https://www.accountingsuite.com',
  supportURL: 'https://support.accountingsuite.com',
  supportPortalURL: 'https://help.accountingsuite.com/portal/home',
  blogURL: 'https://www.accountingsuite.com/blog/',
  currency: 'USD',
  helpLineNumber: '888.328.8275',
  inquireNumber: '415.462.5477',
  acsFranchiseeCode: '000',
  COMMON_ERROR_MESSAGE: 'Sorry something went wrong. Please try again later. ',

  whyAccountingSuiteLink: 'https://www.accountingsuite.com/why-accounting-suite',

  businessLink: 'https://www.accountingsuite.com/bookkeeping-webinars',
  overviewLink: 'https://www.accountingsuite.com/features/',
  findAnAdvisorLink: 'https://www.accountingsuite.com/acs-rockstars',
  accountingReportingLink: 'https://www.accountingsuite.com/accounting-reporting',
  salesLink: 'https://www.accountingsuite.com/sales',
  purchasingLink: 'https://www.accountingsuite.com/purchasing',
  projectAndTimeTrackingLink: 'https://www.accountingsuite.com/project-and-time-tracking',
  inventoryManagementLink: 'https://www.accountingsuite.com/inventory-management',

  partnersLink: 'https://www.accountingsuite.com/accounting-webinars/',
  accountantAndBookkeeperLink: 'https://www.accountingsuite.com/for-partners',
  webinarsLink: 'https://www.accountingsuite.com/accounting-webinars',
  integrationsLink: 'https://www.accountingsuite.com/integrations',

  getStartedLink: 'https://www.accountingsuite.com/free-trial-sign-up',
  userGuideLink: 'https://userguide.accountingsuite.com/user-guide',
  accountingWebinarsLink: 'https://www.accountingsuite.com/webinars_category/accounting-webinars',
  bookkeepingWebinarsLink: 'https://www.accountingsuite.com/webinars_category/bookkeeping-webinars',
  productEnhancementsLink: 'https://www.accountingsuite.com/product-enhancements',
  accountingSuiteAcademyLink: 'https://userguide.accountingsuite.com',
  accountingSuiteStatusLink: 'http://status.accountingsuite.com',
  aboutContactLink: 'https://www.accountingsuite.com/about-contact/',
  pricingLink: 'https://www.accountingsuite.com/pricing',

  linkedInURL: 'https://www.linkedin.com/company/accountingsuite',
  youtubeURL: 'https://www.youtube.com/channel/UC-FCccde_oKN0YyiVx0KgXA',
  twitterURL: 'https://twitter.com/AccountingSuite',
  facebookURL: 'https://www.facebook.com/AccountingSuite',
  termOfServiceURL: '',
  privacyPolicyURL: '',
  // --------Fixed-----------------------//

  // --------Change based on environment-----------------------//
  LOGIN_URL: 'https://predev.accountingsuite.com',
  PAY_URL: 'https://paypredev.accountingsuite.com',
  INTACS_URL: 'https://intacspredev.accountingsuite.com',
  CAT_URL: 'https://catpredev.accountingsuite.com',
  ADD_CARD_URL: 'https://addcardpredev.accountingsuite.com',
  REGISTER_URL: 'https://evening-refuge-30271.herokuapp.com',
  ROCKSTAR_URL: 'https://rockstarpredev.accountingsuite.com',
  OAUTH_URL: 'https://oauthpredev.accountingsuite.com',
  DEVELOPER_URL: 'https://apidocspredev.accountingsuite.com',
  CLIENT_DASHBOARD: 'https://mypredev.accountingsuite.com',
  APP_DEMO_URL: 'https://appdemopredev.accountingsuite.com',

  ACS_LOGO_IMAGE: 'logo-predev.png',
  APPLICATION_DETAILS: {
    NAME: 'TangleSolutions Inc.',
    CLIENT_ID: 'x1qon01ia4qbk3yjrh9b',
    CLIENT_SECRET_KEY: '2bxavy8ybe7po49l8uxc',
    STATE: '123456',
    SOURCE: 'AppUser'
  },
  O_DATA_URL: 'https://dev1.accountingsuite.com/a/ACS_DEV',

  ONEC_SERVICE_USER: 'APIManager',
  ONEC_SECRET_KEY: '123'
  // --------Change based on environment-----------------------//
};
