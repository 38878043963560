import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-icmad-subscribe-now',
  templateUrl: './icmad-subscribe-now.component.html',
  styleUrls: ['./icmad-subscribe-now.component.css']
})
export class IcmadSubscribeNowComponent implements OnInit, OnDestroy, AfterViewInit {

  loginURL: string = environment.LOGIN_URL;
  companyName = 'ICMAD';
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.titleService.setTitle(data.title);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
    const script = this.document.createElement('script');
    script.nonce = '4AEemGb0xJptoIGFP3NR';
    script.type = 'text/javascript';
    script.innerHTML = `var options = {
        widget_id: 'ICMAD-WIDGET',
        buyNow: true
      };
      ACSWidget.init('demo_widget', options);`;
    this.document.getElementById('head').appendChild(script);
    }, 2000);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

}
